import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import OfferFrame from "../OfferFrame";
import SuccessFrame from "../SuccessFrame";
import AgreementFrame from "../AgreementFrame";

import "./style.css";

function App() {
	return (
		<>
			<Router>
				<Switch>
					<Route path="/agreement">
						<AgreementFrame />
					</Route>
					<Route path="/success">
						<SuccessFrame />
					</Route>
					<Route path="/">
						<OfferFrame />
					</Route>
				</Switch>
			</Router>
		</>
	);
}

export default App;
